import { PageCookies } from 'pages';
import React, { lazy, Suspense, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { KeyCloakUtils } from 'utils';
import { APP_PROFILE, PATHS } from 'utils/constants';
import {
  Footer, FooterClimeco, Header, SkeletonMain
} from 'components';
import { useStores } from 'hooks';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';

const PageAdmin = lazy(() => import('pages/PageAdmin'));
const PageCatalog = lazy(() => import('pages/PageCatalog'));
const PageContent = lazy(() => import('pages/PageContent'));
const PageHome = lazy(() => import('pages/PageHome'));
const PageRGPD = lazy(() => import('pages/PageRGPD'));
const PagePublicQuiz = lazy(() => import('pages/PagePublicQuiz'));
const PagePrivateQuiz = lazy(() => import('pages/PagePrivateQuiz'));
const PageModuleDetail = lazy(() => import('pages/PageModuleDetail'));
const PageProgramDetail = lazy(() => import('pages/PageProgramDetail'));
const PageSequenceDetail = lazy(() => import('pages/PageSequenceDetail'));
const PageSequenceFromQRCode = lazy(() => import('pages/PageSequenceFromQRCode'));
const PageTrainees = lazy(() => import('components/TraineeList/TraineeList'));
const PageProfile = lazy(() => import('pages/PageProfile'));

const SkeletonPage = () => (
  <>
    <Header />
    <SkeletonMain />
  </>
);

const withLayout = (BaseComponent) => (props) => (
  <>
    <Header />
    <BaseComponent {...props} />
    {APP_PROFILE === 'AFF' ? <FooterClimeco /> : <Footer />}
  </>
);

const PrivateRoute = ({ Component, ...rest }) => {
  const { userStore } = useStores();
  const { isConnected } = userStore;
  if (CookiesHelper.getChangePasswordStatus()) {
    CookiesHelper.setChangePasswordCookie('false');
    const keycloak = KeyCloakUtils.currentKeycloakInstance();
    keycloak.login();
  }

  const handleRouteRender = useCallback(() => (
    isConnected
      ? withLayout(Component)()
      : <Redirect to={PATHS.HOME} />
    // eslint-disable-next-line
  ), [isConnected, Component]);

  return (
    <Route {...rest} render={handleRouteRender} />
  );
};

const RouteWithLayout = ({ component, ...props }) => <Route component={withLayout(component)} {...props} />;

export const Routes = () => (
  <Suspense fallback={SkeletonPage()}>
    <Switch>
      <RouteWithLayout component={PagePrivateQuiz} exact path={PATHS.PROGRAM_MODULE_SEQUENCE_QUIZ} />
      <RouteWithLayout component={PagePrivateQuiz} path={PATHS.MODULE_SEQUENCE_QUIZ} />
      <RouteWithLayout component={PagePrivateQuiz} path={PATHS.SEQUENCE_QUIZ} />
      <RouteWithLayout component={PagePublicQuiz} exact path={PATHS.QUIZ} />
      <RouteWithLayout component={PageSequenceFromQRCode} path={PATHS.SEQUENCE_FROM_QR_CODE} />
      <RouteWithLayout component={PageSequenceFromQRCode} path={PATHS.PROGRAM_FROM_QR_CODE} />
      <RouteWithLayout component={PageSequenceDetail} exact path={PATHS.PROGRAM_MODULE_SEQUENCE} />
      <RouteWithLayout component={PageSequenceDetail} path={PATHS.MODULE_SEQUENCE} />
      <RouteWithLayout component={PageSequenceDetail} path={PATHS.SEQUENCE} />
      <RouteWithLayout component={PageModuleDetail} exact path={PATHS.PROGRAM_MODULE_DETAIL} />
      <RouteWithLayout component={PageModuleDetail} path={PATHS.MODULE_DETAIL} />
      <RouteWithLayout component={PageModuleDetail} exact path={PATHS.PROGRAM_MODULE} />
      <RouteWithLayout component={PageModuleDetail} path={PATHS.MODULE} />
      <RouteWithLayout component={PageProgramDetail} exact path={PATHS.PROGRAM} />
      <RouteWithLayout component={PageContent} exact path={PATHS.CONTENT} />
      <RouteWithLayout component={PageCatalog} exact path={PATHS.CATALOG} />
      <RouteWithLayout component={PageRGPD} exact path={PATHS.RGPD} />
      <RouteWithLayout component={PageCookies} exact path={PATHS.COOKIES} />
      <RouteWithLayout component={PageHome} path={PATHS.HOME_ECLER} />
      <RouteWithLayout component={PageHome} exact path={PATHS.HOME} />
      <PrivateRoute Component={PageProfile} exact path={PATHS.PROFILE} />
      <PrivateRoute Component={PageAdmin} path={PATHS.ADMINISTRATION} />
      <PrivateRoute Component={PageAdmin} path={PATHS.ADMIN_DOCUMENT} />
      <PrivateRoute Component={PageAdmin} path={PATHS.ADMIN_SEQUENCE} />
      <PrivateRoute Component={PageAdmin} path={PATHS.ADMIN_MODULE} />
      <PrivateRoute Component={PageTrainees} path={PATHS.TRAINEES} />
      <Redirect to={PATHS.HOME} />
    </Switch>
  </Suspense>
);